<template>
    <div class="message" :class="getClass" v-if="message.remark.length === 0">
        <div>
            <div class="message-author" v-if="false && isAdmin">
                {{ this.message.user.name }}
            </div>
            <div class="message-text" v-if="message.content.length > 0">
                <span v-html="text"></span>
            </div>
            <div style="margin: 10px 0 6px 0" v-if="this.message.media.length">
                <Files :files="this.message.media" :align="isDoctor || isAdmin? 'left' : 'right'" />
            </div>
            <div class="message-date">
                <span v-if="false && isSeen">
                    {{ isSeen ? 'Прочитано' : '' }}
                </span>
                {{ new Date(message.created).toLocaleString() }}
            </div>
        </div>
    </div>
</template>

<script>
import Files from './Files.vue'

export default {
    name: 'ChatMessage',
    components: {
        Files
    },
    props: {
        message: {
            type: Object,
            required: true
        }
    },
    computed: {
        text() {
            const change = (s, p1, p2) => {
                return " <a style='color: " + (this.isPatient ? '#fff' : '#1e90ff') + "' href='" + s + "'>" + s + "</a>";
            }
            return this.message.content.replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi, change)
        },
        isPatient() {
            return this.message.user.type === 'patient'
        },
        isDoctor() {
            return this.message.user.type === 'doctor'
        },
        isAdmin() {
            return this.message.user.type === 'admin'
        },
        getClass() {
            if (this.message.user.type === 'patient')
                return 'doctor-message'
            if (this.message.user.type === 'doctor')
                return 'patient-message'
            if (this.message.user.type === 'admin')
                return 'patient-message'
            return 'system-message'
        },
        isSeen() {
            return this.message.user.type === 'doctor' && this.message.seen.filter(it => it.type === 'patient')
        }
    }
}
</script>

<style scoped>

.doctor-message .dropdown-content {
    display: flex;
    align-items: start;
    justify-content: start;
}

.patient-message .dropdown-content {
    display: flex;
    align-items: end;
    justify-content: end;
}

.message {
    max-width: 60%;
    padding-bottom: 12px;
}

.message-text {
    font-size: 13px;
    border-radius: 5px;
    color: #555;
}

.message-author {
    font-size: 12px;
    border-radius: 5px;
    color: #555;
    margin-top: 16px;
}

.message-date {
    font-size: 9.5px;
    letter-spacing: 0.4px;
    color: #d1ccc0;
    font-family: sans-serif;
}

.patient-message .message-text {

    border: 1px solid #eee;
}
.system-message * {
    text-align: center;
}

.patient-message .message-text,
.admin-message .message-text,
.doctor-message .message-text,
.system-message .message-text {
    margin-top: 2px;
    display: inline-block;
    width: auto;
    background: #f3f4f8;
    padding: 6px 16px;
    border-radius: 9px;
}

.doctor-message {
}

.doctor-message .message-text,
.doctor-message .message-text a {
    background: #1e90ff;
    color: #fff !important;
}

.admin-message .message-text,
.admin-message .message-text a {
    background: #2ed573;
    color: #fff !important;
}

.system-message .message-text {
    background: none;
    text-align: center;
}
.system-message {
    margin: 0 auto;
}

.system-message .message-text,
.system-message .message-text a {
    color: #a4b0be !important;
    padding: 0;
}

.doctor-message .message-text a:not(.md-button) {
    color: #fff !important;
}

.doctor-message {
    margin-left: auto;
    margin-right: 16px;
    text-align: right;
}

.admin-message {
    margin-left: auto;
    margin-right: 16px;
    text-align: right;
}

.doctor-message a {
    color: #fff !important;
}

.message-date span {
    margin: 0 4px;
}

.system-message .message-date {
    text-align: center;
    margin: 0;
}

</style>
