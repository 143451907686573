
import Vue from 'vue'
import App from './App.vue'
import VueTheMask from 'vue-the-mask'
import VueMaterial from 'vue-material'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import VueChatScroll from 'vue-chat-scroll'
import VueObserveVisibility from 'vue-observe-visibility'

Vue.use(VueObserveVisibility)
Vue.use(VueChatScroll)
Vue.use(Viewer)
Vue.use(VueTheMask)
Vue.use(VueMaterial)

new Vue({ render: render => render(App) })
    .$mount('#app')

import { initializeApp } from 'firebase/app'
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyD5A2JKrLN1xj4UX1Ex2J7UbrpZOQ1udCw",
    authDomain: "notification-4d5f9.firebaseapp.com",
    databaseURL: "https://notification-4d5f9.firebaseio.com",
    projectId: "notification-4d5f9",
    storageBucket: "notification-4d5f9.appspot.com",
    messagingSenderId: "185008418100",
    appId: "1:185008418100:web:1e0019855634a0894fc961",
    measurementId: "G-NJ75CE9K9E"
}
const app = initializeApp(firebaseConfig)

const messaging = getMessaging(app);

console.log('Azaza')
Notification.requestPermission()
    .then(permission => {
        if (permission === "granted") {
            getToken(messaging, {vapidKey: "AIzaSyD5A2JKrLN1xj4UX1Ex2J7UbrpZOQ1udCw"})
                .then(token => console.log(token))
                .catch(err => console.error(err))
        } else {
            console.log("Unable to get permission to notify.");
        }
    });

