<template>
  <svg style="width: 16px; height: 16px; margin-bottom: 2px; margin-right: 8px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>logout</title>
    <path fill="#fff" d="M16,17V14H9V10H16V7L21,12L16,17M14,2A2,2 0 0,1 16,4V6H14V4H5V20H14V18H16V20A2,2 0 0,1 14,22H5A2,2 0 0,1 3,20V4A2,2 0 0,1 5,2H14Z"/>
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon'
}
</script>
