<template>
  <svg style="width: 24px; height: 24px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>message-plus-outline</title>
    <path fill="tomato" d="M20 2H4C2.9 2 2 2.9 2 4V22L6 18H20C21.1 18 22 17.1 22 16V4C22 2.9 21.1 2 20 2M20 16H5.2L4 17.2V4H20V16M16 9V11H13V14H11V11H8V9H11V6H13V9H16Z"/>
  </svg>
</template>

<script>
export default {
  name: 'MessagePlusOutlineIcon'
}
</script>
