<template>
  <md-app>
    <md-app-content>
      <div class="login-wrapper" v-if="!isAuth">
        <div class="login">
          <h2> Вход </h2>
          <p class="login-error"> {{ error }} </p>
          <md-field>
            <label> Введите номер телефона </label>
            <md-input type="phone" v-model="phone" @input="clearError" v-mask="'+7 9## ### ####'"
                      @keyup.enter="() => { clearedPhone.length === 11 && auth() }"></md-input>
          </md-field>
          <div style="display: flex; align-items: center; justify-content: center; margin-bottom: 56px"
               v-if="code !== null">
            <div>
              <div>
                <small style="text-align: center">Введите код из смс</small>
              </div>
              <div>
                <PincodeInput
                    v-model="code"
                    placeholder=" "
                />
              </div>
            </div>
          </div>
          <button @click="auth" :disabled="disabledLogin"> {{ authBtn }}</button>
        </div>
      </div>
      <div class="main" v-if="isAuth">
        <div class="wrapper" v-if="!loading">
          <div class="sidebar" :class="{'hidden': currentChatId > 0}">
            <div class="sidebar-header">
              <div style="display: flex; align-items: center; justify-content: center">
                <img style="margin: 16px 0 8px; width: 10em;"
                     src="https://www.enchy.ru/upload/iblock/ef2/ef25a2ef179a75a4328d3a64c40328e7.png"
                     alt="Логотип"/>
              </div>
              <div class="label" style="color: #fff; position: relative"> Учетные записи</div>

              <div class="chat-preview profile-freview md-card"
                   v-bind:class="{'chat-preview-active': currentProfile && currentProfile.id === it.id}"
                   v-bind:style="{'opacity': it.hasChats ? 1 : 0.75 }"
                   v-for="it in profiles" :key="it.id" @click="selectProfile(it)">
                <div style="width: 100%"> {{ it.name }}</div>
                <MessagePlusOutlineIcon v-if="it.hasChats"/>
              </div>
              <div class="chat-preview md-card"
                   style="box-shadow: none; display: flex;position: relative; align-items: center; justify-content: center; text-transform: uppercase; text-align: center; background: #faaea226; color: #fff; cursor: pointer"
                   @click="exit()">
                <LogoutIcon/>
                Выйти из чата
              </div>
            </div>
            <span class="label"> Чаты </span>
            <div class="sidebar-search" v-if="chatsList.length > 5">
              <md-field>
                <label>Поиск по ФИО</label>
                <md-input v-model="search"></md-input>
              </md-field>
            </div>
            <div class="sidebar-content">
              <div class="chats-hint" v-if="!hasChats">
                Нет активных чатов
              </div>
              <ChatPreview v-for="it in chatsList" :key="it.id"
                           :chat="it" :currentChatId="currentChatId" :select="selectChat"/>
            </div>

            <div class="offline" v-if="isOffline">
              Подключение...
            </div>
            <div class="test">
              Сервис находится на стадии тестирования
            </div>

          </div>
          <div class="content" v-if="currentChatId > -1" :class="{'hidden': currentChatId < 0}">
            <div class="content-header" :class="{'hidden': currentChatId < 1}" @click="clearChat()">
              <button class="back">
                <ArrowLeftIcon/>
              </button>
              <div class="chat-preview-clients"
                   style="display: flex; flex-direction: column; padding-left: 6px;">
                <div style="display: flex; align-items: center">
                  <img class="photo"
                       :src="`https://lk.sadkomed.ru/customer-assets/media/${photo(chat.users)}/small`"
                       v-if="photo(chat.users)"/>
                  <div>
                    {{
                      chat.users.filter(e => e.id !== currentProfile.id).map(e => e.name).join(", ")
                    }}
                    <div style="font-size: 13px; font-weight: normal; color: #885f01"> Отвечаем в
                      течении 24 часов
                    </div>
                  </div>
                </div>
              </div>
              <div
                  style="width: 56px; height: 56px; display: flex; align-items: center; justify-content: center">
                <transition name="fade">
                  <span class="loader" v-show="isOffline || isLoadingMessages"></span>
                </transition>
              </div>
            </div>

            <div class="messages" v-chat-scroll>
              <ChatMessage :message="it" v-for="it in messages" :key="it.id"></ChatMessage>
            </div>

            <div :class="editorClass" id="form">
              <div>
                <div class="files" v-if="files.length">
                  <div class="file-preview" v-for="(it, index) in files" :key="index">
                    <div class="file-type">
                      {{ getFileType(index) }}
                    </div>
                    <div class="file-size">
                      {{ getFileSize(index) }}
                    </div>
                    <div class="file-shadow"></div>
                  </div>
                </div>
                <textarea v-model="message" placeholder="Введите текст сообщения..."
                          @keyup.enter="send" :disabled="isClosed(byId(currentChatId))"></textarea>
                <button id="send-btn" v-on:click="send" class="send-btn"
                        :disabled="isOffline || isLoadingMessages || isClosed(byId(currentChatId))">
                  {{
                    isClosed(byId(currentChatId)) ? 'Чат завершен' :
                        (uploadPercent === -1 ? 'Отправить' : `Отправка ${uploadPercent}%`)
                  }}
                </button>
                <input style="width: 1px;visibility: hidden" id="files" type="file" ref="oFileHandler"
                       @change="processImage"/>
                <label for="files" class="send-btn" style="background: none; padding: 0 12px">
                  <AttachmentIcon/>
                </label>
              </div>
            </div>

          </div>
          <div class="chat-hint" v-else> Выберите чат</div>
        </div>
        <div v-else>
          Загрузка
        </div>
      </div>

      <div class="bg1" :style="{ 'background-image': `url(${require('@/assets/bg.png')})`}"/>

    </md-app-content>
  </md-app>
</template>

<script>
import axios from "axios";
import SockJS from 'sockjs-client'
import Stomp from 'stompjs';
import moment from 'moment'
import "vue-material/dist/vue-material.min.css";
import "vue-material/dist/theme/default.css";
import {WebSocket} from 'ws';
import ChatMessage from "./ChatMessage.vue";
import ChatPreview from "./ChatPreview.vue";
import PincodeInput from 'vue-pincode-input';
import ArrowLeftIcon from './components/ArrowLeftIcon.vue';
import AttachmentIcon from './components/AttachmentIcon.vue';
import LogoutIcon from './components/LogoutIcon.vue';
import MessagePlusOutlineIcon from './components/MessagePlusOutlineIcon.vue';

Object.assign(global, {WebSocket})

export default {
  name: "Chats",

  components: {
    ChatMessage, ChatPreview, PincodeInput, ArrowLeftIcon, AttachmentIcon, LogoutIcon, MessagePlusOutlineIcon
  },

  data() {
    return {
      search: '',
      loading: true,
      loadingPhone: false,
      loadingCode: false,
      phone: '',
      code: null,
      error: '',
      chats: [],
      currentChatId: -1,
      message: '',
      messages: [],
      messagesSeen: [],
      profiles: [],
      currentProfile: null,
      messagesSubscription: null,
      stompClient: null,
      isOffline: true,
      isLoadingMessages: false,
      files: [],
      isSend: false,
      isSendFiles: false,
      uploadPercent: -1
    }
  },

  mounted() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.style.setProperty('--vh2', `${vh}px`)
    window.addEventListener('resize', () => {
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    })

    this.checkToken().then(res => {
      if (res)
        this.connect()
      else
        this.exit()
    })

    if (localStorage.getItem('phone') !== null) {
      this.phone = localStorage.getItem('phone')
      this.code = ''
    }
  },

  watch: {
    currentProfile(value, oldValue) {
      this.currentChatId = -1

      const onMessageReceived = message => {
        const chat = JSON.parse(message.body)
        console.log('currentProfile onMessageReceived')
        console.log(chat)
        if (this.chats.some(it => it.id === chat.id)) {
          const chats = this.chats.filter(it => it.id !== chat.id)
          chats.push(chat)
          this.chats = chats
        } else {
          this.chats.push(chat)
        }
        const messages = this.messages
        messages.sort((b, a) => new Date(a.created).getTime() - new Date(b.created).getTime())
        this.loadMessages().then(chat => {
          if (chat.messages && messages[0].id !== chat.messages[0].id)
            this.playSound()
        })
      }
      if (this.stompClient !== null) {
        if (oldValue) {
          if (this.messagesSubscription)
            this.messagesSubscription.unsubscribe()
        }
        this.messagesSubscription = this.stompClient.subscribe(`/patient/${value.uid}`, onMessageReceived)
      }
      this.loadChats()
    }

  },

  computed: {

    isAuth() {
      const res = !(localStorage.getItem('token') === null || localStorage.getItem('salt') === null) || this.profiles.length > 0
      console.log(res)
      return !(localStorage.getItem('token') === null || localStorage.getItem('salt') === null) || this.profiles.length > 0
    },

    hasChats() {
      return this.chatsList.length > 0
    },

    clearedPhone() {
      return this.phone.split('').filter(it => '01234567890'.indexOf(it) > -1).join('')
    },

    disabledLogin() {
      if (this.loadingCode || this.loadingPhone) return true
      if (this.code === null) {
        if (this.clearedPhone.length !== 11) return true
        if (this.error.length > 0) return true
      } else if (this.code.length > 0) return false
      return false
    },

    authBtn() {
      if (this.code === null) return 'Запросить код'
      else return 'Войти'
    },

    chatsList() {
      const chats = this.chats
      chats.sort((a, b) => new Date(b.messages[0].created).getTime() - new Date(a.messages[0].created).getTime())
      return chats
    },

    chat() {
      return this.chats.filter(it => it.id === this.currentChatId)[0]
    },

    editorClass() {
      if (this.files.length)
        return 'editor editor-files'
      return 'editor'
    }

  },

  methods: {

    processImage(event) {
      console.log('processImage')
      this.files = [];
      [...event.target.files].forEach(file =>
          this.files.push({data: file, name: file.name, size: file.size}))
    },

    byId(chatId) {
      return this.chats.filter(it => it.id === chatId)[0]
    },

    isClosed(chat) {
      return new Date().getTime() > new Date(chat.deleted).getTime()
    },

    visibilityChanged(it, e, ee) {
      if (e && it.seen.map(user => user.id).indexOf(this.currentProfile.id) === -1 && this.messagesSeen.indexOf(it.id) === -1) {
        this.messagesSeen.push(it.id)
        this.seen(it)
      }
    },

    seen(message) {
      this.stompClient.send("/app/seen", {}, JSON.stringify({
        userId: this.currentProfile.id,
        messageId: message.id
      }))
    },

    photo(users) {
      if (!users) return ''
      const currentUsers = users.filter(it => it.type === 'doctor')
      return currentUsers[0].media
    },

    exit() {
      localStorage.removeItem('token')
      localStorage.removeItem('phone')
      localStorage.removeItem('salt')
      this.code = null
      this.loading = false
      this.messages = []
      this.profiles = []
      this.clearError()
    },

    connect() {
      const onConnected = () => {
        this.isOffline = false
        this.loadMessages()

        const onMessageReceived = message => {
          const chat = JSON.parse(message.body)
          console.log('connect onMessageReceived')
          console.log(chat)
          if (this.chats.some(it => it.id === chat.id)) {
            const chats = this.chats.filter(it => it.id !== chat.id)
            chats.push(chat)
            this.chats = chats
          } else {
            this.chats.push(chat)
          }
          this.loadMessages()
          this.playSound()
        }

        if (this.currentProfile !== null) {
          if (this.messagesSubscription)
            this.messagesSubscription.unsubscribe()
          this.messagesSubscription = this.stompClient.subscribe(`/patient/${this.currentProfile.uid}`, onMessageReceived)
        }
      }

      const connect = () => {
        const sock = new SockJS("/chats/ws")
        this.stompClient = Stomp.over(sock)
        this.stompClient.heartbeat.outgoing = 1000 * 3
        this.stompClient.heartbeat.incoming = 1000 * 3
        this.stompClient.connect({}, onConnected, onError);
      }

      const onError = err => {
        console.error(err)
        this.isOffline = true
        this.stompClient.disconnect()
        setTimeout(() => connect(), 1000)
      }

      connect()
    },

    async checkToken() {
      const token = this.getToken()
      const salt = this.getSalt()

      if (!token || !salt) {
        localStorage.removeItem('salt')
        localStorage.removeItem('token')
        console.error('Token or salt is missing in localStorage')
        return false
      }

      try {
        const result = await axios.get(`/chats/token/${token}/${salt}`)
        if (result.status === 200) {
          if (result.data === 'false' || result.data === false) {
            this.exit()
            return false
          }
          this.profiles = result.data
          if (this.profiles.length === 1) this.currentProfile = this.profiles[0]
          this.loading = false
          return result.data !== false
        }
      } catch (error) {
        console.error('Error checking token:', error)
        return false
      }
    },

    getToken() {
      return localStorage.getItem('token')
    },

    setToken(token) {
      if (token === null) localStorage.removeItem('token')
      localStorage.setItem('token', token)
    },

    getSalt() {
      return localStorage.getItem('salt')
    },

    setSalt(salt) {
      if (salt === null) localStorage.removeItem('salt')
      localStorage.setItem('salt', salt)
    },

    clearError() {
      this.error = ''
    },

    async auth() {
      let salt = localStorage.getItem('salt')
      let phone = this.phone

      if (salt === null || salt === 'null') {
        salt = new Date().getTime()
        localStorage.setItem('salt', salt.toString())
      }
      if (phone === null) {
        console.error('Token or salt is missing in localStorage')
        return false
      } else {
        if (this.code === null) {
          try {
            const result = await axios.get(`/chats/code/${this.clearedPhone}/${salt}`)
            if (result.data === 200) {
              this.code = ''
            }
            console.log('authPhone')
            console.log(result)
          } catch (e) {
            console.error(e)
            return false
          }
        } else {
              try {
                this.loadingCode = true
                const res = await axios.get(`/chats/code/${this.clearedPhone}/${this.code}/${salt}`)
                if (res.data === false) {
                  setTimeout(() => {
                    this.error = 'Код не подходит'
                  }, 1)
                  this.code = ''
                } else {
                  this.clearError()
                  this.code = ''
                  this.setToken(res.data)
                  await this.checkToken()
                }
              } catch (e) {
                console.log(e)
              } finally {
                this.loadingCode = false
              }
        }
      }

    },

    playSound() {
      const audio = new Audio(require('@/assets/bb.mp3'))
      audio.play()
    },

    getMessageDate(it) {
      if (new Date().toLocaleDateString() === new Date(it).toLocaleDateString())
        return moment(new Date(it), 'HH:mm', 'ru').format('HH:mm')
      else return moment(new Date(it), 'HH:mm - D MMM', 'ru').format('HH:mm - D MMM')
    },

    getFileType(index) {
      const data = this.files[index].name.split('.')
      return data[data.length - 1]
    },

    getFileSize(index) {
      // if (this.files[index].size > 2500000) return 'Много'
      return `${(this.files[index].size / 1024 / 1024).toFixed(1)}мб`
    },

    send() {
      if (this.isSend) return
      this.isSend = true
      const currentFiles = this.files.filter(it => it.size < 250000000)
      if ((this.isSendFiles && currentFiles.length === 0) && this.message === '')
        return
      if (currentFiles.length > 0) {
        this.isSendFiles = true
        const form = new FormData()
        form.append('messageData', [this.currentChatId, this.currentProfile.id, this.message])
        currentFiles.forEach(it => form.append('files', it.data))
        this.files = []
        this.message = ''
        axios.post(`/chats/media/upload`, form, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.uploadPercent = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100))
          }
        }).then(res => console.log(res.data)).finally(() => {
          this.isSend = false
          this.isSendFiles = false
          this.uploadPercent = -1
          this.files = []
          this.message = ''
        })
      } else {
        document.getElementById('send-btn').blur()
        if (this.message.trim().length === 0) {
          this.message = ''
          return
        }
        if (this.currentProfile === null) {
          alert('Выберите профиль')
          return
        }
        this.stompClient.send("/app/v2/chat", {}, `[${this.currentChatId}, ${this.currentProfile.id}, "${this.message}"]`)
        this.isSend = false
        this.files = []
        this.message = ''
      }
    },

    selectChat(chat) {
      if (this.currentChatId === chat.id) this.clearChat()
      else {
        this.clearChat()
        this.currentChatId = chat.id
        this.loadMessages()
      }
    },

    clearChat() {
      this.currentChatId = -1
      this.messages = []
    },

    async loadMessages() {
      console.log('loadMessages')
      if (this.currentChatId === -1 || this.isLoadingMessages) return
      const time = new Date().getTime()
      this.isLoadingMessages = true
      const res = await axios.get(`/chats/messages/${this.currentChatId}`)
      const delta = new Date().getTime() - time
      setTimeout(() => {
        this.messagesSeen = []
        this.messages = res.data
        this.isLoadingMessages = false
      }, delta > 150 ? 150 : 300)
      const messages = res.data
      console.log('messages')
      messages.sort((b, a) => new Date(b.created).getTime() - new Date(a.created).getTime())
      return messages
    },

    async loadChats() {
      const res = await axios.get(`/chats/patient/${this.currentProfile.id}`)
      this.chats = res.data
    },

    selectProfile(it) {
      if (this.currentProfile !== it)
        this.chats = []
      this.currentProfile = it
    }

  }
}
</script>

<style scoped>

.files {
  display: flex;
  padding-bottom: 16px;
}

.file-preview {
  width: 56px;
  height: 56px;
  border: 1px solid #e1e1e1;
  border-radius: 7px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-right: 10px;
}

.file-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  background: #21212155;
  display: block;
  z-index: 2;
}

.file-type {
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  z-index: 3;
}

.file-size {
  font-size: 12px;
  text-transform: lowercase;
  color: #fff;
  z-index: 3;
}

.md-app-content {
  position: relative;
  background: #4bb0fe;
  transition: all 2s;
}

.main-svg {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  height: 100%;
  /*animation-name: rotatesimp;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 10s;*/
}

.chats-hint {
  text-align: center;
  margin-top: 56px
}

html,
body,
.md-app,
.md-app-container,
.md-app-content,
.wrapper {

  height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100) !important;
  height: -webkit-fill-available;
  max-height: 100vh !important; /* Use vh as a fallback for browsers that do not support Custom Properties */
  max-height: calc(var(--vh, 1vh) * 100) !important;
  max-height: -webkit-fill-available;
  overflow: hidden;
}

.wrapper {
  display: flex;
}

.login-wrapper {
  display: flex;
  height: calc(100vh);
  display: flex;
  align-items: center;
  justify-content: center
}

.md-app-content {
  overflow: hidden;
  padding: 0 !important;
}

* {
  font-family: "Fira Sans";
}

::-webkit-scrollbar-button {
  background-image: url("");
  background-repeat: no-repeat;
  width: 6px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 2px;
  border-radius: 2px;
  width: 4px;
  background-color: #448aff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #448aff;
}

::-webkit-resizer {
  background-image: url("");
  background-repeat: no-repeat;
  width: 4px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 4px;
}

.sidebar {
  background: #f5f5f5;
  display: flex;
  flex-direction: column;
  width: 400px;
  max-width: 400px;
  min-width: 400px;
  overflow: hidden;
  border-right: 1px solid #eee;
  position: relative;
  z-index: 1;
}

.offline {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 16px;
  background: tomato;
  border-radius: 9px;
  color: #fff;
  padding: 8px 16px;
  text-align: center;
  z-index: 1;
  box-shadow: 5px 0 2px rgb(0 10 30 / 2%), 3px 4px 4px rgb(0 10 30 / 4%), 1px 8px 6px rgb(0 10 30 / 6%);
}

.test {
  position: absolute;
  left: 24px;
  right: 24px;
  bottom: 16px;
  color: #aaa;
  padding: 8px 16px;
  text-align: center;
  z-index: -1;
}

.label {
  display: block;
  text-align: center;
  padding: 12px 0 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #404344;
  font-weight: 400;
  font-size: 12px;
}

.sidebar-search {
  height: 72px;
  padding: 0 16px;
}

.sidebar-content {
  height: 100%;
  overflow-y: auto;
}

.sidebar-item-active {
  background: #4489ff38 !important;
}

.count {
  display: inline-block;
  background: tomato;
  width: 18px;
  border-radius: 50%;
  color: white;
  padding: 2px;
  height: 17px;
}

.md-card {
  box-shadow: 5px 0 2px rgb(0 10 30 / 2%),
  3px 4px 4px rgb(0 10 30 / 4%),
  1px 8px 6px rgb(0 10 30 / 6%);
  border-radius: 8px;
}


.chat-hint {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: #fff;
  z-index: 1;
  font-size: 16px;
}

.login {
  width: 400px;
  display: flex;
  flex-direction: column;
  background: #f1f1f1;
  padding: 32px;
  margin: 32px;
  cursor: pointer;
  border-radius: 12px;
  box-shadow: 0 8px 32px #23232377;
  z-index: 1;
}

.login h2 {
  margin-top: 0;
  padding-top: 0;
  text-align: center;
}


.login-error {
  display: block;
  line-height: 24px;
  text-align: center;
  color: tomato;
  margin-bottom: 16px;
}

.login button {
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  background: #42b983;
  color: #fff;
}

.login button:disabled {
  border: none;
  padding: 10px 16px;
  border-radius: 6px;
  background: #9ebbae;
  color: #fff;
}

.chat-preview {
  background: white;
  margin-bottom: 12px;
  padding: 12px 16px;
  cursor: pointer;
}

.profile-freview {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.chat-preview-active {
  background: #f0ffff;
}

.chat-preview:hover {
  background: #e0ffff;
}

.chat-preview-clients {
  font-weight: 500;
  display: flex;
  color: #404344;
  letter-spacing: 0.45px;
  font-size: 15px;
  width: 100%;
}

.chat-preview-content {
  display: flex;
  align-items: center;
}

.chat-preview-text {
  color: #404344;
  font-size: 14px;
  width: 100%;
  margin-right: 16px;
  margin-top: 6px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.chat-preview-date {
  font-size: 12px;
  padding-top: 1px;
  letter-spacing: calc(1px / 4);
  color: #888;
  font-family: monospace;
}

.rooms-search i {
  width: 48px;
  color: #929aa2;
}

.rooms-search input {
  width: 100%;
  padding: 0;
  height: 32px;
  border: none;
  background: none;
  color: #929aa2;
}

.rooms-search input:focus {
  outline: none;
  color: #000;
}


.message {
}

.message-text {
  font-size: 15px;
  border-radius: 5px;
  color: #555;
  word-break: break-word;
}

.message-date {
  font-size: 10px;
  padding: 2px 4px;
  letter-spacing: 0.25px;
  color: deepskyblue;
  font-family: monospace;
  display: flex;
  align-items: center;
}


.patient-message .message-text {

  border: 1px solid #eee;
}

.patient-message .message-text,
.doctor-message .message-text,
.system-message .message-text {
  margin-top: 12px;
  display: inline-block;
  width: auto;
  background: #f7f7fc;
  padding: 8px 12px;
  border-radius: 11px;
}

.doctor-message .message-date {
  justify-content: right;
}


.patient-message .message-date {
  justify-content: left;
}

.system-message {
  width: 100% !important;
  display: block;
}

.system-message .message-text {
  background: none;
  margin: 16px 0;
  display: block;
  width: 100%;
  max-width: calc(100% - 16px) !important;
  text-align: center;
}

.doctor-message .message-text,
.doctor-message .message-text a {
  background: #3182ff;
  color: #fff !important;
}

.doctor-message .message-text a:not(.md-button) {
  color: #fff !important;
}

.message-text {
  max-width: 40em;
}

.doctor-message {
  margin-left: auto;
  margin-right: 16px;
  text-align: right;
}

.doctor-message a {
  color: #fff !important;
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.content {
  width: 100%;
  height: 100%;
  margin-left: 0;
  margin-right: 0;
  overflow: auto;
  background: rgb(247, 249, 251);
  display: flex;
  flex-direction: column;
  z-index: 1;
}

.messages {
  flex: 1;
  overflow: auto;
  margin-bottom: 12px;
  border-bottom: 1px solid #eee;
  background: #fff;
  padding-left: 16px;
}


.editor-files {
  height: calc(108px + 72px) !important;
  border: 1px solid #eee;
  position: relative;
  border-radius: 12px;
  padding: 12px;
  margin: 0 12px 12px;
  background: #fff;
}

.editor {
  height: 108px;
  border: 1px solid #eee;
  position: relative;
  border-radius: 12px;
  padding: 12px;
  margin: 0 12px 12px;
  background: #fff;
}


.editor span {
  margin-right: 12px;
  border-radius: 6px;
  background: #eee;
  padding: 2px 8px;
  font-size: 12px;
  margin-bottom: 4px;
}

.editor textarea {
  background: none;
  border: none;
  height: calc(96px - 12px - 12px - 24px);
  width: calc(100%);
  outline: none;
}

.send-btn {
  bottom: 24px;
  right: 24px;
  padding: 4px 16px;
  margin: 4px;
  float: right;
  border: none;
  background: #7bed9f;
  border-radius: 5px;
  font-family: sans-serif;
  font-size: 12px;
  font-weight: normal;
  display: block;
  line-height: 16px;
}

.send-btn:hover {
  cursor: pointer;
  color: #000;
  border-color: #555;
  background: #eee;
}

.content-header {
  height: 72px;
  display: flex;
  align-items: center;
  padding: 0 8px;
  border-bottom: 1px solid #eee;
  background: #cefcfc
}

.content-header .back {
  border: none;
  color: #404344;
  background: none;
  display: none;
}

.editor-hint {
  color: #999;
  padding-top: 8px;
  display: inline-block
}

@media (max-width: 1025px) {
  .sidebar {
    width: 100%;
    min-width: 100%;
    border-right: none;
  }

  .message-text {
    max-width: 20em;
  }

  .editor-hint {
    display: none;
  }

  .hidden {
    display: none;
  }

  .messages {
    height: calc(100vh - 133px - 56px);
    background-repeat: repeat;
  }

  .content-header {
    color: #404344;
  }

  .content-header .back {
    border: none;
    color: #404344;
    background: none;
    display: block;
    padding-right: 8px;
  }
}

.sidebar-header {
  position: relative;
  padding-bottom: 24px;
  margin-bottom: 24px;
}

.sidebar-header * {
  z-index: 2;
}

.sidebar-header .label {
  z-index: 2;
}

.sidebar-header:before,
.sidebar-header:after {
  content: "";
  position: absolute;
  left: 50%;
  min-width: 900vw;
  min-height: 900vw;
  background-color: #FCFFF5;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  background-image: linear-gradient(to right, #4facfe 0%, #00f2fe 100%);
  z-index: 0;
}

.sidebar-header:before {
  bottom: 1vh;
  border-radius: 50.5%;
  animation-duration: 10s;
}

.sidebar-header:after {
  bottom: 0vh;
  opacity: .5;
  border-radius: 49.5%;
  animation-duration: 10s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -0.25%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}

@keyframes rotatesimp {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(180deg);
  }
}


.loader {
  width: 40px !important;
  height: 40px !important;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  border: 3px solid;
  border-color: #0077b6 #0077b6 transparent transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

.loader::after,
.loader::before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 3px solid;
  border-color: transparent transparent #90e0ef #90e0ef;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-sizing: border-box;
  animation: rotationBack 0.5s linear infinite;
  transform-origin: center center;
}

.loader::before {
  width: 20px;
  height: 20px;
  border-color: #00b4d8 #00b4d8 transparent transparent;
  animation: rotation 1.5s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotationBack {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}

.bg1 {
  position: absolute;
  background-size: cover; /* <------ */
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 0;
  top: 0;
  height: 1000px;
  width: 1000px !important;
  animation: 45s linear 1s infinite rotation;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */
{
  opacity: 0;
}

.messages-bg {
  opacity: 0.6;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  background-repeat: repeat;
}

.photo {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid lightblue;
  margin-right: 16px;
}

.chat-preview-closed {
  opacity: 0.5;
}
</style>
