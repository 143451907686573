<template>
    <div class="files" :style="{'justify-content': align}">
        <a class="file-preview" target="_blank" rel="noopener noreferrer" :href="`${host}/media/${it.id}`" :style="{'margin': align === 'left' ? '0 10px 0 0 ' : '0 0 0 10px'}" v-for="(it, index) in files" :key="index">
            <div class="file-type">
                {{ getFileType(index) }}
            </div>
            <div class="file-size">
                {{ getFileSize(index) }}
            </div>
            <div class="file-shadow" :style="{'background': getBackground(it)}"></div>
            <img class="file-image" v-if="isImage(it)" :src="`${host}/media/${it.id}`"  alt=""/>
        </a>
    </div>
</template>

<script>
const HOST = process.env.NODE_ENV === 'production' ? '/chats' : 'http://192.168.100.72:8888'

export default {
    name: 'Files',
    props: {
        files: {
            type: Array,
            required: true,
            default: () => []
        },
        align: {
            type: String,
            required: false,
            default: () => 'left'
        }
    },
    computed: {
        host() {
            return HOST
        }
    },
    methods: {
        getBackground(file) {
            if (this.isImage(file)) return '#21212155'
            return '#AAB69B'
        },

        isImage(file) {
            return ['image/jpeg'].indexOf(file.type) > -1
        },

        getFileType(index) {
            const data = this.files[index].name.split('.')
            return data[data.length - 1]
        },

        getFileSize(index) {
            return `${(this.files[index].size / 1024 / 1024).toFixed(1)}мб`
        }
    }
}
</script>

<style scoped>

.files {
    display: flex;
}

.files a {
    text-decoration: none;
}

.file-preview {
    cursor: pointer;
    width: 56px;
    height: 56px;
    border: 1px solid #e1e1e1;
    border-radius: 7px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
}

.file-shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    background: #21212155;
    display: block;
    z-index: 2;
}

.file-type {
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    z-index: 3;
}

.file-size {
    font-size: 12px;
    text-transform: lowercase;
    color: #fff;
    z-index: 3;
}

.file-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    object-fit: cover;
}

</style>